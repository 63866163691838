<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card class="cardClassDetailForm" title="상세">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn v-if="popupParam.pmMstId &&!disabled" label="삭제" icon="delete_forever" @btnClicked="removeData" />
                <c-btn v-if="!popupParam.pmMstId && !disabled" label="PM마스터 복사" color="teal-custom" icon="save_alt" @btnClicked="copyData" />
                <c-btn
                  v-if="editable &&!disabled"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-plant
                  :required="true"
                  :disabled="disabled"
                  :editable="editable"
                  name="plantCd"
                  v-model="data.plantCd">
                </c-plant>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-equip
                  :required="true"
                  :disabled="disabled"
                  :editable="editable"
                  label="설비"
                  name="equipmentCd"
                  v-model="data.equipmentCd">
                </c-equip>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :required="true"
                  :disabled="disabled"
                  :editable="editable"
                  label="PM명"
                  name="pmMstName"
                  v-model="data.pmMstName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-dept type="edit" :editable="editable" label="요청부서" :required="true" name="reqDeptCd" v-model="data.reqDeptCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-dept type="edit" :isFirstValue="false" :editable="editable" label="작업부서" :required="true" name="workDeptCd" v-model="data.workDeptCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-select
                  :required="true"
                  :editable="editable"
                  codeGroupCd="PM_TYPE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="pmTypeCd"
                  label="PM유형"
                  v-model="data.pmTypeCd"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-datepicker
                  label="정비 예상시간"
                  name="pmTime"
                  type="time"
                  :minuteStep="10"
                  v-model="data.pmTime"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  v-if="data.pmTypeCd=='TBM'"
                  :required="data.pmTypeCd=='TBM'"
                  :disabled="disabled"
                  :editable="editable"
                  label="PM 주기"
                  name="pmCycleTerm"
                  v-model="data.pmCycleTerm">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-select
                  v-if="data.pmTypeCd=='TBM'"
                  :required="data.pmTypeCd=='TBM'"
                  :editable="editable"
                  type="edit"
                  codeGroupCd="PM_CYCLE_UNIT_CD"
                  itemText="codeName"
                  itemValue="code"
                  name="pmCycleUnitCd"
                  label="PM주기 단위"
                  v-model="data.pmCycleUnitCd"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-datepicker
                  v-if="data.pmTypeCd=='TBM'"
                  :required="data.pmTypeCd=='TBM'"
                  default="today"
                  label="최초 시작일"
                  name="pmFirstDt"
                  v-model="data.pmFirstDt"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-textarea
                  :editable="editable"
                  :disabled="disabled"
                  :rows="1"
                  label="작업요청사항"
                  name="remark"
                  v-model="data.remark">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card class="cardClassDetailForm no-margin-top" title="작업계획 정보">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-table
                  ref="grid1"
                  title="작업단계"
                  :columns="grid1.columns"
                  :data="grid1.data"
                  :gridHeight="grid1.height"
                  :editable="editable&&!disabled"
                  :hideBottom="true"
                  :isExcelDown="false"
                  :filtering="false"
                  :isFullScreen="false"
                  :columnSetting="false"
                  selection="multiple"
                  rowKey="pmWorkPlanStepId"
                >
                  <template slot="table-button">
                    <q-btn-group outline >
                      <c-btn label="추가" icon="add" @btnClicked="addRow1" v-if="editable&&!disabled" />
                      <c-btn label="삭제" icon="remove" @btnClicked="removeRow1" v-if="editable&&!disabled" />
                    </q-btn-group>
                  </template>
                </c-table>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <!-- @table-data-change="tableDataChange3" -->
                <c-table
                  ref="grid3"
                  title="작업자재"
                  :columns="grid3.columns"
                  :data="grid3.data"
                  :gridHeight="grid3.height"
                  :editable="editable&&!disabled"
                  :hideBottom="true"
                  :isExcelDown="false"
                  :filtering="false"
                  :columnSetting="false"
                  selection="multiple"
                  rowKey="materialCd"
                >
                  <template slot="table-button">
                    <q-btn-group outline >
                      <c-btn label="추가" icon="add" @btnClicked="addRow3" v-if="editable&&!disabled" />
                      <c-btn label="삭제" icon="remove" @btnClicked="removeRow3" v-if="editable&&!disabled" />
                    </q-btn-group>
                  </template>
                </c-table>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-table
                  ref="grid2"
                  title="작업자"
                  :columns="grid2.columns"
                  :data="grid2.data"
                  :gridHeight="grid2.height"
                  :editable="editable&&!disabled"
                  :hideBottom="true"
                  :isExcelDown="false"
                  :filtering="false"
                  :columnSetting="false"
                  selection="multiple"
                  rowKey="workerId"
                >
                  <template slot="table-button">
                    <q-btn-group outline >
                      <c-btn label="추가" icon="add" @btnClicked="addRow2" v-if="editable&&!disabled" />
                      <c-btn label="삭제" icon="remove" @btnClicked="removeRow2" v-if="editable&&!disabled" />
                    </q-btn-group>
                  </template>
                </c-table>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-table
                  ref="grid4"
                  title="작업용역"
                  :columns="grid4.columns"
                  :data="grid4.data"
                  :gridHeight="grid4.height"
                  :editable="editable&&!disabled"
                  :hideBottom="true"
                  :isExcelDown="false"
                  :filtering="false"
                  :columnSetting="false"
                  selection="multiple"
                  rowKey="vendorCd"
                >
                  <template slot="table-button">
                    <q-btn-group outline >
                      <c-btn label="추가" icon="add" @btnClicked="addRow4" v-if="editable&&!disabled" />
                      <c-btn label="삭제" icon="remove" @btnClicked="removeRow4" v-if="editable&&!disabled" />
                    </q-btn-group>
                  </template>
                </c-table>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-table
                  ref="grid5"
                  title="작업공구"
                  :columns="grid5.columns"
                  :data="grid5.data"
                  :gridHeight="grid5.height"
                  :editable="editable&&!disabled"
                  :hideBottom="true"
                  :isExcelDown="false"
                  :filtering="false"
                  :columnSetting="false"
                  selection="multiple"
                  rowKey="pmWorkPlanToolId"
                >
                  <template slot="table-button">
                    <q-btn-group outline >
                      <c-btn label="추가" icon="add" @btnClicked="addRow5" v-if="editable&&!disabled" />
                      <c-btn label="삭제" icon="remove" @btnClicked="removeRow5" v-if="editable&&!disabled" />
                    </q-btn-group>
                  </template>
                </c-table>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="margin-top:20px">
                <c-upload
                  :attachInfo="attachInfo"
                  :editable="editable&&!disabled"
                  label="첨부파일">
                </c-upload>
              </div>
            </template>
          </c-card>
        </div>
      </div>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'preventive-maintenance-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        pmMstId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      deleteUrl1: '',
      deleteUrl2: '',
      deleteUrl3: '',
      deleteUrl4: '',
      deleteUrl5: '',
      disabled: false,
      popupOptions: {
        isFull: false,
        width: '50%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'PM_MST',
        taskKey: '',
      },
      editable: true,
      saveUrl: transactionConfig.pm.mst.insert.url,
      mappingType: 'POST',
      detailUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      isSave: false,
      isdelete: false,
      data: {
        plantCd: '',  // 사업장코드
        pmMstId: '',  // PM 마스터 일련번호
        pmMstName: '',  // PM 마스터 명
        reqDeptCd: '',  // 요청부서
        workDeptCd: '',  // 작업부서
        pmTypeCd: null,  // PM 유형코드
        pmTime: '',  // PM예상시간
        pmCycleTerm: '',  // PM주기
        pmCycleUnitCd: null,  // PM주기 단위
        pmFirstDt: '',  // PM최초 시작일
        pmLastDt: '',  // PM최초 시작일
        remark: '',  // 작업요청 사항
        equipmentCd: '',  // 작업요청 사항
        pmSteps: [],
        pmMaterials: [],
        pmWorkers: [],
        pmServices: [],
        pmTools: [],
      },
      grid1: {
        columns: [
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '순서',
            align: 'left',
            style: 'width:60px',
            type: 'number',
            required: true,
            sortable: false,
          },
          {
            name: 'mdmSwsStepName',
            field: 'mdmSwsStepName',
            label: '작업단계',
            align: 'left',
            type: 'text',
            required: true,
            sortable: false,
          },
          {
            name: 'contents',
            field: 'contents',
            label: '작업 상세',
            type: 'text',
            align: 'left',
            sortable: false,
          },
          {
            name: 'termTm',
            field: 'termTm',
            label: '작업시간',
            align: 'center',
            style: 'width:100px',
            type: 'datetime',
            datetimeType: 'time', 
            sortable: false,
          },
        ],
        data: [],
        height: '250px',
      },
      grid2: {
        columns: [
          {
            name: 'workerName',
            field: 'workerName',
            label: '작업자',
            align: 'center',
            required: true,
            sortable: false,
          },
          // {
          //   name: 'termDt',
          //   field: 'termDt',
          //   label: '작업일',
          //   align: 'center',
          //   type: 'date',
          //   style: 'width: 120px',
          //   setHeader: true,
          //   sortable: false,
          // },
          // {
          //   name: 'termStartTm',
          //   field: 'termStartTm',
          //   label: '시작시간',
          //   align: 'center',
          //   type: 'datetime',
          //   minuteStep: 10,
          //   style: 'width: 120px',
          //   setHeader: true,
          //   sortable: false,
          // },
          // {
          //   name: 'termEndTm',
          //   field: 'termEndTm',
          //   label: '종료시간',
          //   align: 'center',
          //   type: 'datetime',
          //   minuteStep: 10,
          //   style: 'width: 120px',
          //   setHeader: true,
          //   sortable: false,
          // },
        ],
        data: [],
        height: '250px',
      },
      grid3: {
        columns: [
          {
            name: 'materialName',
            field: 'materialName',
            label: '자재',
            align: 'center',
            required: true,
            sortable: false,
          },
          {
            name: 'materialDesc',
            field: 'materialDesc',
            label: '용도',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          // {
          //   name: 'unitPrice',
          //   field: 'unitPrice',
          //   label: '단가',
          //   type: 'number',
          //   style: 'width: 100px',
          //   sortable: false,
          // },
          // {
          //   name: 'unitEa',
          //   field: 'unitEa',
          //   label: '수량',
          //   type: 'number',
          //   style: 'width: 80px',
          //   sortable: false,
          // },
          // {
          //   name: 'priceCost',
          //   field: 'priceCost',
          //   label: '소계',
          //   type: 'cost',
          //   style: 'width: 100px',
          //   sortable: false,
          // },
        ],
        data: [],
        height: '250px',
      },
      grid4: {
        columns: [
          {
            name: 'vendorName',
            field: 'vendorName',
            label: '업체명',
            align: 'center',
            required: true,
            sortable: false,
          },
          {
            name: 'workContents',
            field: 'workContents',
            label: '작업내용',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          // {
          //   name: 'manCnt',
          //   field: 'manCnt',
          //   label: '투입인원',
          //   type: 'number',
          //   style: 'width: 80px',
          //   sortable: false,
          // },
          // {
          //   name: 'priceCost',
          //   field: 'priceCost',
          //   label: '용역비용',
          //   type: 'number',
          //   style: 'width: 120px',
          //   sortable: false,
          // },
        ],
        data: [],
        height: '250px',
      },
      grid5: {
        columns: [
          {
            name: 'toolName',
            field: 'toolName',
            label: '공구명',
            align: 'center',
            type: 'text',
            required: true,
            sortable: false,
          },
          // {
          //   name: 'toolCnt',
          //   field: 'toolCnt',
          //   label: '사용 개수',
          //   align: 'center',
          //   type: 'number',
          //   style: 'width: 80px',
          //   sortable: false,
          // },
        ],
        data: [],
        height: '250px',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.detailUrl = selectConfig.pm.mst.get.url;
      this.insertUrl = transactionConfig.pm.mst.insert.url;
      this.updateUrl = transactionConfig.pm.mst.update.url;
      this.deleteUrl = transactionConfig.pm.mst.delete.url;
      this.deleteUrl1 = transactionConfig.pm.mst.step.delete.url;
      this.deleteUrl2 = transactionConfig.pm.mst.worker.delete.url;
      this.deleteUrl3 = transactionConfig.pm.mst.material.delete.url;
      this.deleteUrl4 = transactionConfig.pm.mst.service.delete.url;
      this.deleteUrl5 = transactionConfig.pm.mst.tool.delete.url;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.pmMstId) {
        this.$http.url = this.detailUrl;
        this.$http.param = {pmMstId: this.popupParam.pmMstId};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          this.grid1.data = _result.data.pmSteps;
          this.grid2.data = _result.data.pmWorkers;
          this.grid3.data = _result.data.pmMaterials;
          this.grid4.data = _result.data.pmServices;
          this.grid5.data = _result.data.pmTools;
          this.$set(this.attachInfo, 'taskKey', this.popupParam.pmMstId)
        },);
      }
    },
    saveData() {
      if (this.popupParam.pmMstId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }

      let isConti1 = true;
      let checkItem1 = ['sortOrder', 'mdmSwsStepName']
      this.$_.forEach(this.grid1.data, item => {
        this.$_.forEach(checkItem1, check => {
          if (!item[check]) {
            isConti1 = false;
            return false;
          }
        })
      }); 
      if (!isConti1) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '필수 입력값을 입력해 주세요. [작업순서-순서, 작업단계]',
          type: 'warning', // success / info / warning / error
        });
        return false;
      } 
      let isConti2 = true;
      let checkItem2 = ['workerName']
      this.$_.forEach(this.grid2.data, item => {
        this.$_.forEach(checkItem2, check => {
          if (!item[check]) {
            isConti2 = false;
            return false;
          }
        })
      }); 
      if (!isConti2) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '필수 입력값을 입력해 주세요. [작업자]',
          type: 'warning', // success / info / warning / error
        });
        return false;
      } 
      // let isConti3 = true;
      // let checkItem3 = ['unitEa']
      // this.$_.forEach(this.grid3.data, item => {
      //   this.$_.forEach(checkItem3, check => {
      //     if (!item[check]) {
      //       isConti3 = false;
      //       return false;
      //     }
      //   })
      // }); 
      // if (!isConti3) {
      //   window.getApp.$emit('ALERT', {
      //     title: '안내', // 안내
      //     message: '필수 입력값을 입력해 주세요. [자재수량]',
      //     type: 'warning', // success / info / warning / error
      //   });
      //   return false;
      // } 
      // let isConti4 = true;
      // let checkItem4 = ['manCnt']
      // this.$_.forEach(this.grid4.data, item => {
      //   this.$_.forEach(checkItem4, check => {
      //     if (!item[check]) {
      //       isConti4 = false;
      //       return false;
      //     }
      //   })
      // }); 
      // if (!isConti4) {
      //   window.getApp.$emit('ALERT', {
      //     title: '안내', // 안내
      //     message: '필수 입력값을 입력해 주세요. [용역 투입인원]',
      //     type: 'warning', // success / info / warning / error
      //   });
      //   return false;
      // } 
      let isConti5 = true;
      let checkItem5 = ['toolName']
      this.$_.forEach(this.grid5.data, item => {
        this.$_.forEach(checkItem5, check => {
          if (!item[check]) {
            isConti5 = false;
            return false;
          }
        })
      }); 
      if (!isConti5) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '필수 입력값을 입력해 주세요. [공구명]',
          type: 'warning', // success / info / warning / error
        });
        return false;
      } 
      let saveData1 = this.grid1.data.filter( x => {
        return x.editFlag == 'C' || x.editFlag == 'U'
      });
      let saveData2 = this.grid2.data.filter( x => {
        return x.editFlag == 'C' || x.editFlag == 'U'
      });
      let saveData3 = this.grid3.data.filter( x => {
        return x.editFlag == 'C' || x.editFlag == 'U'
      });
      let saveData4 = this.grid4.data.filter( x => {
        return x.editFlag == 'C' || x.editFlag == 'U'
      });
      let saveData5 = this.grid5.data.filter( x => {
        return x.editFlag == 'C' || x.editFlag == 'U'
      });
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              this.data.pmSteps = saveData1;
              this.data.pmMaterials = saveData3;
              this.data.pmServices = saveData4;
              this.data.pmTools = saveData5;
              this.data.pmWorkers = saveData2;

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    removeData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '작업요청을 삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.pmMstId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isdelete = !this.isdelete;
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.pmMstId = result.data.pmMstId
      this.$set(this.attachInfo, 'taskKey', this.popupParam.pmMstId)
      this.$set(this.attachInfo, 'isSubmit', uid())
      this.getDetail();
    },
    addRow1() {
      this.grid1.data.push({
        pmMstId: this.popupParam.pmMstId,  // 작업지시번호
        pmWorkPlanStepId: uid(),  // 작업계획 작업절차일련번호
        mdmSwsStepName: '',  // 작업절차
        contents: '',  // 작업상세
        termTm: '',  // 예상 소요시간
        sortOrder: this.grid1.data.length + 1,  // 작업절차 순서
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        chgUserId: this.$store.getters.user.userId,  // 수정자 ID
        editFlag: 'C',
      });
    },
    removeRow1() {
      let selectData = this.$refs['grid1'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteUrl1);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid1.data = this.$_.reject(this.grid1.data, item);
              })
              this.$refs['grid1'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addRow2() {
      this.popupOptions.title = '작업자 추가'; // 작업자 추가
      this.popupOptions.param = {
        type: 'multiple',
        jobKinds: [],
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.width = '99%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeaddRow2;
    },
    closeaddRow2(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.grid2.data, { workerId: item.userId }) === -1) {
            this.grid2.data.push({
              pmMstId: this.popupParam.pmMstId,  // 작업지시번호
              workerId: item.userId,  // 작업자 사번
              workerName: item.userName,
              termDt: '',  // 작업일
              termStartTm: '',  // 시작시간
              termEndTm: '',  // 종료시간
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              editFlag: 'C',
            })
          }
        });
      }
    },
    removeRow2() {
      let selectData = this.$refs['grid2'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteUrl2);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid2.data = this.$_.reject(this.grid2.data, item);
              })
              this.$refs['grid2'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addRow3() {
      this.popupOptions.title = '작업자재 추가'; // 작업자재 추가
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/material/materialPop.vue'}`);
      this.popupOptions.width = '99%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeaddRow3;
    },
    closeaddRow3(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.grid3.data, { materialCd: item.materialCd }) === -1) {
            this.grid3.data.push({
              pmMstId: this.popupParam.pmMstId,  // 작업지시번호
              materialCd: item.materialCd,  // 작업자재 일련번호
              materialName: item.materialName,  // 작업자재
              materialDesc: '',  // 작업자재 용도
              unitPrice: item.price,  // 단가
              unitEa: '',  // 수량
              priceCost: '',  // 소계
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              editFlag: 'C',
            })
          }
        });
      }
    },
    removeRow3() {
      let selectData = this.$refs['grid3'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteUrl3);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid3.data = this.$_.reject(this.grid3.data, item);
              })
              this.$refs['grid3'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    tableDataChange3(props) {
      if (props.row['unitPrice'] > 0 && props.row['unitEa'] > 0) {
        this.$set(props.row, 'priceCost', props.row['unitPrice'] * props.row['unitEa']);
      } else {
        this.$set(props.row, 'priceCost', 0);
      }
    },
    addRow4() {
      this.popupOptions.title = '작업용역 추가'; // 작업자 추가
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/vendor/vendorPop.vue'}`);
      this.popupOptions.width = '99%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeaddRow4;
    },
    closeaddRow4(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.grid4.data, { vendorCd: item.vendorCd }) === -1) {
            this.grid4.data.push({
              pmMstId: this.popupParam.pmMstId,  // 작업지시번호
              vendorCd: item.vendorCd,  // 용역업체 일련번호
              vendorName: item.vendorName,  // 용역업체
              workContents: '',  // 작업내용
              manCnt: '',  // 예상 투입인원
              priceCost: '',  // 용역비용
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              editFlag: 'C',
            })
          }
        });
      }
    },
    removeRow4() {
      let selectData = this.$refs['grid4'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteUrl4);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid4.data = this.$_.reject(this.grid4.data, item);
              })
              this.$refs['grid4'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addRow5() {
      this.grid5.data.push({
        pmMstId: this.popupParam.pmMstId,  // 작업지시번호
        pmWorkPlanToolId: uid(),  // 작업계획 작업공구 일련번호
        toolName: '',  // 공구명
        toolCnt: '',  // 예상 사용개수
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        chgUserId: this.$store.getters.user.userId,  // 수정자 ID
        editFlag: 'C',
      });
    },
    removeRow5() {
      let selectData = this.$refs['grid5'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteUrl5);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid5.data = this.$_.reject(this.grid5.data, item);
              })
              this.$refs['grid5'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    copyData() {
      this.popupOptions.title = 'PM마스터 복사대상 검색'; // PM마스터 복사대상 검색
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/pm/pmMstPop.vue'}`);
      this.popupOptions.width = '99%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeCopyData;
    },
    closeCopyData(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$http.url = this.detailUrl;
        this.$http.param = {pmMstId: data[0].pmMstId};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          this.data.pmMstId = null;
          this.data.pmMstName = '';
          this.data.pmFirstDt = '';
          this.data.equipmentCd = '';
          
          this.grid1.data = [];
          this.grid2.data = [];
          this.grid3.data = [];
          this.grid4.data = [];
          this.grid5.data = [];
          this.$_.forEach(_result.data.pmSteps, items1 => {
            items1.editFlag = 'C';
          })
          this.grid1.data = _result.data.pmSteps;
          
          this.$_.forEach(_result.data.pmWorkers, items2 => {
            items2.editFlag = 'C';
          })
          this.grid2.data = _result.data.pmWorkers;
          
          this.$_.forEach(_result.data.pmMaterials, items3 => {
            items3.editFlag = 'C';
          })
          this.grid3.data = _result.data.pmMaterials;
          
          this.$_.forEach(_result.data.pmServices, items4 => {
            items4.editFlag = 'C';
          })
          this.grid4.data = _result.data.pmServices;
          
          this.$_.forEach(_result.data.pmTools, items5 => {
            items5.editFlag = 'C';
          })
          this.grid5.data = _result.data.pmTools;
        },);
      }
    },
  }
};
</script>