var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-form", { ref: "editForm" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "상세" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.popupParam.pmMstId && !_vm.disabled
                            ? _c("c-btn", {
                                attrs: {
                                  label: "삭제",
                                  icon: "delete_forever",
                                },
                                on: { btnClicked: _vm.removeData },
                              })
                            : _vm._e(),
                          !_vm.popupParam.pmMstId && !_vm.disabled
                            ? _c("c-btn", {
                                attrs: {
                                  label: "PM마스터 복사",
                                  color: "teal-custom",
                                  icon: "save_alt",
                                },
                                on: { btnClicked: _vm.copyData },
                              })
                            : _vm._e(),
                          _vm.editable && !_vm.disabled
                            ? _c("c-btn", {
                                attrs: {
                                  url: _vm.saveUrl,
                                  isSubmit: _vm.isSave,
                                  param: _vm.data,
                                  mappingType: _vm.mappingType,
                                  label: "저장",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.saveData,
                                  btnCallback: _vm.saveCallback,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-plant", {
                          attrs: {
                            required: true,
                            disabled: _vm.disabled,
                            editable: _vm.editable,
                            name: "plantCd",
                          },
                          model: {
                            value: _vm.data.plantCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "plantCd", $$v)
                            },
                            expression: "data.plantCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-equip", {
                          attrs: {
                            required: true,
                            disabled: _vm.disabled,
                            editable: _vm.editable,
                            label: "설비",
                            name: "equipmentCd",
                          },
                          model: {
                            value: _vm.data.equipmentCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "equipmentCd", $$v)
                            },
                            expression: "data.equipmentCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            required: true,
                            disabled: _vm.disabled,
                            editable: _vm.editable,
                            label: "PM명",
                            name: "pmMstName",
                          },
                          model: {
                            value: _vm.data.pmMstName,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "pmMstName", $$v)
                            },
                            expression: "data.pmMstName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-dept", {
                          attrs: {
                            type: "edit",
                            editable: _vm.editable,
                            label: "요청부서",
                            required: true,
                            name: "reqDeptCd",
                          },
                          model: {
                            value: _vm.data.reqDeptCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "reqDeptCd", $$v)
                            },
                            expression: "data.reqDeptCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-dept", {
                          attrs: {
                            type: "edit",
                            isFirstValue: false,
                            editable: _vm.editable,
                            label: "작업부서",
                            required: true,
                            name: "workDeptCd",
                          },
                          model: {
                            value: _vm.data.workDeptCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "workDeptCd", $$v)
                            },
                            expression: "data.workDeptCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-select", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            codeGroupCd: "PM_TYPE_CD",
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "pmTypeCd",
                            label: "PM유형",
                          },
                          model: {
                            value: _vm.data.pmTypeCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "pmTypeCd", $$v)
                            },
                            expression: "data.pmTypeCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            label: "정비 예상시간",
                            name: "pmTime",
                            type: "time",
                            minuteStep: 10,
                          },
                          model: {
                            value: _vm.data.pmTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "pmTime", $$v)
                            },
                            expression: "data.pmTime",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _vm.data.pmTypeCd == "TBM"
                          ? _c("c-text", {
                              attrs: {
                                required: _vm.data.pmTypeCd == "TBM",
                                disabled: _vm.disabled,
                                editable: _vm.editable,
                                label: "PM 주기",
                                name: "pmCycleTerm",
                              },
                              model: {
                                value: _vm.data.pmCycleTerm,
                                callback: function ($$v) {
                                  _vm.$set(_vm.data, "pmCycleTerm", $$v)
                                },
                                expression: "data.pmCycleTerm",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _vm.data.pmTypeCd == "TBM"
                          ? _c("c-select", {
                              attrs: {
                                required: _vm.data.pmTypeCd == "TBM",
                                editable: _vm.editable,
                                type: "edit",
                                codeGroupCd: "PM_CYCLE_UNIT_CD",
                                itemText: "codeName",
                                itemValue: "code",
                                name: "pmCycleUnitCd",
                                label: "PM주기 단위",
                              },
                              model: {
                                value: _vm.data.pmCycleUnitCd,
                                callback: function ($$v) {
                                  _vm.$set(_vm.data, "pmCycleUnitCd", $$v)
                                },
                                expression: "data.pmCycleUnitCd",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _vm.data.pmTypeCd == "TBM"
                          ? _c("c-datepicker", {
                              attrs: {
                                required: _vm.data.pmTypeCd == "TBM",
                                default: "today",
                                label: "최초 시작일",
                                name: "pmFirstDt",
                              },
                              model: {
                                value: _vm.data.pmFirstDt,
                                callback: function ($$v) {
                                  _vm.$set(_vm.data, "pmFirstDt", $$v)
                                },
                                expression: "data.pmFirstDt",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            rows: 1,
                            label: "작업요청사항",
                            name: "remark",
                          },
                          model: {
                            value: _vm.data.remark,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "remark", $$v)
                            },
                            expression: "data.remark",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm no-margin-top",
                  attrs: { title: "작업계획 정보" },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c(
                          "c-table",
                          {
                            ref: "grid1",
                            attrs: {
                              title: "작업단계",
                              columns: _vm.grid1.columns,
                              data: _vm.grid1.data,
                              gridHeight: _vm.grid1.height,
                              editable: _vm.editable && !_vm.disabled,
                              hideBottom: true,
                              isExcelDown: false,
                              filtering: false,
                              isFullScreen: false,
                              columnSetting: false,
                              selection: "multiple",
                              rowKey: "pmWorkPlanStepId",
                            },
                          },
                          [
                            _c(
                              "template",
                              { slot: "table-button" },
                              [
                                _c(
                                  "q-btn-group",
                                  { attrs: { outline: "" } },
                                  [
                                    _vm.editable && !_vm.disabled
                                      ? _c("c-btn", {
                                          attrs: { label: "추가", icon: "add" },
                                          on: { btnClicked: _vm.addRow1 },
                                        })
                                      : _vm._e(),
                                    _vm.editable && !_vm.disabled
                                      ? _c("c-btn", {
                                          attrs: {
                                            label: "삭제",
                                            icon: "remove",
                                          },
                                          on: { btnClicked: _vm.removeRow1 },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c(
                          "c-table",
                          {
                            ref: "grid3",
                            attrs: {
                              title: "작업자재",
                              columns: _vm.grid3.columns,
                              data: _vm.grid3.data,
                              gridHeight: _vm.grid3.height,
                              editable: _vm.editable && !_vm.disabled,
                              hideBottom: true,
                              isExcelDown: false,
                              filtering: false,
                              columnSetting: false,
                              selection: "multiple",
                              rowKey: "materialCd",
                            },
                          },
                          [
                            _c(
                              "template",
                              { slot: "table-button" },
                              [
                                _c(
                                  "q-btn-group",
                                  { attrs: { outline: "" } },
                                  [
                                    _vm.editable && !_vm.disabled
                                      ? _c("c-btn", {
                                          attrs: { label: "추가", icon: "add" },
                                          on: { btnClicked: _vm.addRow3 },
                                        })
                                      : _vm._e(),
                                    _vm.editable && !_vm.disabled
                                      ? _c("c-btn", {
                                          attrs: {
                                            label: "삭제",
                                            icon: "remove",
                                          },
                                          on: { btnClicked: _vm.removeRow3 },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c(
                          "c-table",
                          {
                            ref: "grid2",
                            attrs: {
                              title: "작업자",
                              columns: _vm.grid2.columns,
                              data: _vm.grid2.data,
                              gridHeight: _vm.grid2.height,
                              editable: _vm.editable && !_vm.disabled,
                              hideBottom: true,
                              isExcelDown: false,
                              filtering: false,
                              columnSetting: false,
                              selection: "multiple",
                              rowKey: "workerId",
                            },
                          },
                          [
                            _c(
                              "template",
                              { slot: "table-button" },
                              [
                                _c(
                                  "q-btn-group",
                                  { attrs: { outline: "" } },
                                  [
                                    _vm.editable && !_vm.disabled
                                      ? _c("c-btn", {
                                          attrs: { label: "추가", icon: "add" },
                                          on: { btnClicked: _vm.addRow2 },
                                        })
                                      : _vm._e(),
                                    _vm.editable && !_vm.disabled
                                      ? _c("c-btn", {
                                          attrs: {
                                            label: "삭제",
                                            icon: "remove",
                                          },
                                          on: { btnClicked: _vm.removeRow2 },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c(
                          "c-table",
                          {
                            ref: "grid4",
                            attrs: {
                              title: "작업용역",
                              columns: _vm.grid4.columns,
                              data: _vm.grid4.data,
                              gridHeight: _vm.grid4.height,
                              editable: _vm.editable && !_vm.disabled,
                              hideBottom: true,
                              isExcelDown: false,
                              filtering: false,
                              columnSetting: false,
                              selection: "multiple",
                              rowKey: "vendorCd",
                            },
                          },
                          [
                            _c(
                              "template",
                              { slot: "table-button" },
                              [
                                _c(
                                  "q-btn-group",
                                  { attrs: { outline: "" } },
                                  [
                                    _vm.editable && !_vm.disabled
                                      ? _c("c-btn", {
                                          attrs: { label: "추가", icon: "add" },
                                          on: { btnClicked: _vm.addRow4 },
                                        })
                                      : _vm._e(),
                                    _vm.editable && !_vm.disabled
                                      ? _c("c-btn", {
                                          attrs: {
                                            label: "삭제",
                                            icon: "remove",
                                          },
                                          on: { btnClicked: _vm.removeRow4 },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                      },
                      [
                        _c(
                          "c-table",
                          {
                            ref: "grid5",
                            attrs: {
                              title: "작업공구",
                              columns: _vm.grid5.columns,
                              data: _vm.grid5.data,
                              gridHeight: _vm.grid5.height,
                              editable: _vm.editable && !_vm.disabled,
                              hideBottom: true,
                              isExcelDown: false,
                              filtering: false,
                              columnSetting: false,
                              selection: "multiple",
                              rowKey: "pmWorkPlanToolId",
                            },
                          },
                          [
                            _c(
                              "template",
                              { slot: "table-button" },
                              [
                                _c(
                                  "q-btn-group",
                                  { attrs: { outline: "" } },
                                  [
                                    _vm.editable && !_vm.disabled
                                      ? _c("c-btn", {
                                          attrs: { label: "추가", icon: "add" },
                                          on: { btnClicked: _vm.addRow5 },
                                        })
                                      : _vm._e(),
                                    _vm.editable && !_vm.disabled
                                      ? _c("c-btn", {
                                          attrs: {
                                            label: "삭제",
                                            icon: "remove",
                                          },
                                          on: { btnClicked: _vm.removeRow5 },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                        staticStyle: { "margin-top": "20px" },
                      },
                      [
                        _c("c-upload", {
                          attrs: {
                            attachInfo: _vm.attachInfo,
                            editable: _vm.editable && !_vm.disabled,
                            label: "첨부파일",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }